'use strict';

import "@babel/polyfill";
import 'slick-carousel';
import { ua } from './view/_ua';
import { header } from './view/_header';
import { height } from './view/_height';
import { scroll } from './view/_scroll';
import { smoothScroll } from './view/_smoothScroll';
import { slider } from './view/_slider';
import { news } from './view/_news';
import { footer } from './view/_footer';
