const _menuBtn = $('#js-hamburger')
const _header = $('#js-header')
const _navigation = $('#js-navigation')
const _subMenuBtn = $('.has-child')
_menuBtn.click(function(){
	$('html').toggleClass('is-fixed')
	_menuBtn.toggleClass('is-open')
	_navigation.toggleClass('is-show')
})
_subMenuBtn.click(function(){
	$(this).toggleClass('is-open')
})

var _timer = '';
$(window).on('load resize', function(){
	if (_timer) {
		clearTimeout(_timer);
	}
	_timer = setTimeout(function(){
		let _pos = 0;
		function ScrollAnime() {
			var _elemTop = $('.l-main').offset().top;
			var _scroll = $(window).scrollTop();
			var _height = _header.outerHeight(true);
			if(!(_menuBtn.hasClass('is-open'))){
				if(_scroll == _pos) {
				}else if(_scroll == 0){
					_header.removeClass('is-fixed');
				}else{
					_header.addClass('is-fixed');
				}
				_pos = _scroll;
			}
		}
		$(window).scroll(function(){
			ScrollAnime();
		});
	}, 200);
});
