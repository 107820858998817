$('.js-slider').slick({
	autoplay: true,
	autoplaySpeed: 3500,
	variableWidth: true,
	arrows: true,
	appendArrows: $('.p-homeKv__controls'),
	nextArrow: '<button type="button" class="js-next"></button>',
	prevArrow: '<button type="button" class="js-prev"></button>',
	dots: true,
	dotsClass: 'js-dots',
	appendDots: $('.p-homeKv__controls')
});

$('.js-footerSlider').slick({
	autoplay: true,
	autoplaySpeed: 0,
	speed: 6900,
	variableWidth: true,
	cssEase: "linear",
	arrows: false,
});
